import { css, keyframes } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledNamePageBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [14, 14, 34, 34]
  })
)

export const styledNamePage = css`
  ${styledNamePageBps};
`

export const h4Style = css(
  mq({
    fontSize: [33.6, 33.6, 33.6, 47.9],
    fontWeight: [500, 500, 500, 'normal']
  })
)

export const titleStyle = css(
  mq({
    fontSize: [33.6, 33.6, 33.6, 47.9],
    fontWeight: [600, 600, 600, 'normal']
  })
)

export const h2Style = css(
  mq({
    fontSize: [47.9, 47.9, 47.9, 59.9],
    fontWeight: [500, 500, 500, 'normal']
  })
)

export const h3Style = css(
  mq({
    fontSize: [47.9]
  })
)

/* HEADER */
export const H4HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    marginTop: [8],
    marginBottom: [16],
    letterSpacing: [0.25],
    textAlign: ['left'],
    fontSize: [33.6, 33.6, 33.6, 47.9]
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    textAlign: ['left']
  })
)

export const spanBps = css(
  mq({
    textAlign: ['left']
  })
)

export const lineBorderBps = css(
  mq({
    maxWidth: [328, 422, 680, 680]
  })
)

export const linkCardBps = css(
  mq({
    fontSize: [14, 14, 14, 18]
  })
)

export const header = css`
  display: flex;
  text-align: center;
  span {
    ${spanBps};
    text-transform: uppercase;
    font-size: 11.8px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
    display: grid;
  }
  p {
    ${PHeaderBps};
    margin-bottom: 16px;
  }
`
export const item = css`
  transition: .3s ease-out,transform .3s ease-out,opacity .2s ease-out;
  transition-delay: .1s;
  transition: all .25s linear;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.12);
  :hover {
    transform: translate(0, -4px);
    box-shadow: -4px 10px 20px 0px rgba(0,0,0,0.12);
  }
`

export const cloud = css`
  background-color: black;
  height: 35px;
  width: 120px;
  position: absolute;
  border-radius: 120px;
  z-index: -3;

  &::before{
    position: absolute;
    content: "";
    background-color: black;
    height: 65px;
    width: 65px;
    border-radius: 50%;
    bottom: 10px;
    left: 15px;
  }

  &::after{
    position: absolute;
    content: "";
    background-color: black;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    bottom: 10px;
    right: 10px;
  }
`

export const cloudAni = keyframes`
  100%{
    transform: translateX(-1000px);
  }
`

export const jetpackFloat = keyframes`
  0% {
    transform: translate3d(0, 0, 0)
  }
  50% {
    transform: translate3d(0, 30px, 0)
  }
  100% {
    transform: translate3d(0, 0, 0)
  }
`

export const imgAni = css`
  animation: ${jetpackFloat} 5s ease-in-out infinite;
`
export const imgHomeBox = css(
  mq({
    width: [70, 70, 114, 132],
    height: [70, 70, 114, 132]
  })
)

export const solutionContent = css`
  h6 {
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 16px;
  }
  p {
    margin-bottom: 24px;
    color: rgba(0, 0, 0, 0.6);
  }
`

export const btn = css`
  :active{
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
  }
  :hover {
    background-color: red;
  }
`

export const linkCard = css`
  ${linkCardBps};
  color: #FF5800;
  padding: 6px 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  :hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.08);
    padding: 6px 8px;
    border-radius: 4px;
  }
`